/* contact:
 *
 * Change contact link according to modelname
 *
 * Author: Niels Giesen
 * Copyright 2016, 2019 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  var _mapping = {
    // https://regelhulptest.bridge-to-knowledge.nl/ez-test/rvo.html?modelname=brandveiligheid
    // Contactpagina maken zoals nu nog te zien is op: http://ibm.regelhulpenvoorbedrijven.nl/BrandveiligheidContactPopup.jsp
    brandveiligheid: ["contact-brandveiligheid.html"],
    // https://regelhulptest.bridge-to-knowledge.nl/ez-test/rvo.html?modelname=premiekortingen
    // Contactpagina maken zoals nu nog te zien is op: http://ibm.regelhulpenvoorbedrijven.nl/PremiekortingenContactPopup.jsp
    premiekortingen: ["contact-premiekortingen.html"],
    // https://regelhulptest.bridge-to-knowledge.nl/ez-test/rvo.html?modelname=legionella
    // Contactpagina maken zoals nu nog te zien is op: http://ibm.regelhulpenvoorbedrijven.nl/LegionellaContactPopup.jsp
    legionella: ["contact-legionella.html"],
    regelhulpmest: [
      "http://www.rvo.nl/over-ons/over-ons/wat-doet-rvonl/contact"
    ],
    rekenhulplkv: ["contact-premiekortingen.html"],
    e_besparing: [
      "https://www.rvo.nl/over-rvonl/contact/alle-contactmogelijkheden-op-een-rij"
    ],
    wbso: ["https://www.rvo.nl/subsidies-regelingen/wbso"],
    vastgoed_transformatie: [
      "https://www.rvo.nl/onderwerpen/duurzaam-ondernemen/gebouwen/stad-en-regio/transformatie/expertteam"
    ],
    quotumcalculator: [
      "https://www.rijksoverheid.nl/ministeries/ministerie-van-sociale-zaken-en-werkgelegenheid/contact",
      "Heeft u vragen over de quotumregeling? Neem contact op met het Ministerie van Sociale Zaken en Werkgelegenheid via https://www.rijksoverheid.nl/ministeries/ministerie-van-sociale-zaken-en-werkgelegenheid/contact."
    ],
    lkv: [
      "https://www.rijksoverheid.nl/ministeries/ministerie-van-sociale-zaken-en-werkgelegenheid/contact",
      "Heeft u vragen over de quotumregeling? Neem contact op met het Ministerie van Sociale Zaken en Werkgelegenheid via https://www.rijksoverheid.nl/ministeries/ministerie-van-sociale-zaken-en-werkgelegenheid/contact."
    ],
    efactureren: [
      "https://www.helpdesk-efactureren.nl/",
      "Heeft u vragen? https://www.helpdesk-efactureren.nl/contact."
    ],
    einvoice: [
      "https://www.helpdesk-efactureren.nl/",
      "Any questions? https://www.helpdesk-efactureren.nl/contact."
    ],
    ce_markering: [
      "https://www.rvo.nl/over-ons/contact",
      "Heeft u vragen? https://www.rvo.nl/over-ons/contact"
    ],
    internationaalmkb: [
      "https://www.rvo.nl/onderwerpen/persoonlijk-advies-buitenland",
      "Heeft u vragen? https://www.rvo.nl/onderwerpen/persoonlijk-advies-buitenland"
    ],
    xpw20: [
      "https://www.rvo.nl/onderwerpen/persoonlijk-advies-buitenland",
      "Heeft u vragen? https://www.rvo.nl/onderwerpen/persoonlijk-advies-buitenland"
    ],
    regelhulpbtegemoetkomingschadecovid: [
      "https://www.rvo.nl/subsidie-en-financieringswijzer/tegemoetkoming-schade-covid-19",
      "Heeft u vragen over duurzaam, agrarisch, innovatief of internationaal ondernemen? Neem dan contact met ons op. U kunt ons op verschillende manieren bereiken."
    ],
    rie: ["https://www.rie.nl/contact/", "Heeft u vragen? https://www.rie.nl/"],
    kringloopscan: [
      "https://www.groeiennaarmorgen.nl/contact",
      "Heeft u vragen? https://www.platformkringlooplandbouw.nl/contact"
    ],
    regelhulpchina: [
      "https://www.rvo.nl/over-ons/contact",
      "Heeft u vragen? https://www.rvo.nl/over-ons/contact"
    ],
    regelhulpbetrouwbaarheidniveaus: [
      "https://www.digitaletoegang.nl/contact",
      "Komt u er met de regelhulp  niet uit? Stuur ons dan een bericht via https://www.digitaletoegang.nl/contact of per e-mail: digitaletoegang@minbzk.nl"
    ]
  };

  $(function () {
    var $links = $(".p-contact-link");
    $(doc).on("bb:postHandleData", function (event, data) {
      if (data && data.modelname) {
        var url = _mapping[data.modelname];
        if (url) {
          $links.attr("href", url[0]).removeAttr("hidden");
          if (url[1]) {
            $links.attr("title", url[1]);
          }
        } else {
          $links.removeAttr("href").attr("hidden", true);
        }
      }
    });
  });
})(jQuery, window, document);
