import { bb } from "$json";
/* has-no-next:
 *
 * Set body.hasNoNext when the current node name matches a
 * predefined regular expression, and unsets it when does not.
 *
 * Author: Niels Giesen
 *
 * Copyright (C) 2011, 2012, 2017 by Berkeley Bridge
 *
 */

(function ($) {
  // Regular expression to test node names against:
  var re = /^(Einde)|(Resultaat)|(End)|(Result)$/,
    implemented = ["rekenhulplkv"];

  $(document).on("bb:postHandleData", function (event, data) {
    if (data && data.jumplist) {
      var nonext =
        data.hasnext == false ||
        data.jumplist.some(function (group) {
          return (
            group.status == "selected" && re.test($.trim(group.screentitle))
          );
        });

      bb.Mode.toggle("hasNoNext", nonext);
      bb.Mode.toggle(
        "offersReturnUrl",
        nonext &&
          implemented.indexOf(data.modelname) > -1 &&
          !data.groups.some(function (group) {
            return group.current && /\.fout$/.test(group.name);
          })
      );
    }
  });
})(jQuery);
