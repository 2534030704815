import { _ } from "$json";

const DICTIONARY = {
  "close this infomation box": {
    "en": "close this infomation box",
    "nl": "sluit dit informatievak",
    "en-GB": "close this infomation box"
  },
  "Send link popup": {
    "en": "Send link popup",
    "nl": "Pop-up voor koppeling verzenden",
    "en-GB": "Send link popup"
  }
};

_.addTranslations(DICTIONARY);

class Listener {
  constructor(id) {
    this[id] = event => {
      if (event.isComposing || event.keyCode !== 27) {
        return;
      } else {
        // No need to be specific, just destroy the most recent one.
        Array.from(document.querySelectorAll(".p-dialogue-container"))
          .pop()
          .click();
      }
    };
  }
}

export default class Dialogue {
  constructor(props = {}, parent) {
    this.id = `dialogue-${Date.now()}-${Math.floor(Math.random() * 10000)}`;
    this.parent =
      parent !== undefined && typeof parent === "object" && "nodeType" in parent
        ? parent
        : document.getElementsByTagName("body")[0];
    this.elemprops = props;
    this.rtnfocus = document.children[0];
  }

  destroy(human = true) {
    let me = document.getElementById(this.id);
    if (me) this.parent.removeChild(me);

    // Remove keyup listener
    this.handler &&
      document.removeEventListener(`keyup`, this.handler[this.id], false);

    // Untrap focus
    document.querySelectorAll("[data-hushed-by-dialogue]").forEach(elem => {
      let prev = elem.getAttribute("data-previous-tabindex");
      elem.removeAttribute("tabindex");
      if (prev !== "none") {
        elem.setAttribute("tabindex", prev);
      }
      elem.removeAttribute("data-previous-tabindex");
      elem.removeAttribute("data-hushed-by-tabindex");
    });

    // Unhide ARIA
    document
      .querySelectorAll("[data-aria-hidden-by-dialogue]")
      .forEach(elem => {
        let prev = elem.getAttribute("data-previous-aria");
        elem.removeAttribute("aria-hidden");
        if (prev !== "none") {
          elem.setAttribute("aria-hidden", prev);
        }
        elem.removeAttribute("data-previous-aria");
        elem.removeAttribute("data-aria-hidden-by-dialogue");
      });

    // Return focus, but not if this was called by the render method,
    // otherwise we will override the activeElement with the initial
    // rtnfocus value (document.children[0])
    human && this.rtnfocus.focus();
  }

  render(elem) {
    // Remove dialogue if it already exists.
    this.destroy(false);

    // Remove all other focusable elements from tabindex on the DOM,
    // so we can truly trap the focus for all users w/o trapping
    // keyboard user out of the browser controls.
    document
      .querySelectorAll(
        "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]"
      )
      .forEach(elem => {
        elem.setAttribute("data-hushed-by-dialogue", true);
        elem.setAttribute(
          "data-previous-tabindex",
          elem.getAttribute("tabindex") || "none"
        );
        elem.setAttribute("tabindex", "-1");
      });

    // ARIA hide everything to avoid screen readers keyboard
    // shortcuts gaining access outside of the dialogue.
    document.querySelectorAll("*:not(body):not(html)").forEach(elem => {
      elem.setAttribute("data-aria-hidden-by-dialogue", true);
      elem.setAttribute(
        "data-previous-aria",
        elem.getAttribute("aria-hidden") || "none"
      );
      elem.setAttribute("aria-hidden", "true");
    });

    // Create elements.
    let container = document.createElement("div");
    container.className = "p-dialogue-container";
    container.style.cssText = `
      background-color: rgba(0,0,0,.5);
      height: 100%;
      left: 0;
      position: fixed;
      text-align: center;
      top: 0;
      width: 100%;
      z-index: 100;
    `;
    container.id = this.id;
    container.setAttribute("role", "dialog");
    // container.setAttribute("aria-label", "dialog"); // BAD A11Y - more accessible label should be given by class caller.
    Object.keys(this.elemprops).forEach(prop => {
      prop === "class"
        ? (container.className += " " + this.elemprops[prop])
        : container.setAttribute(prop, this.elemprops[prop]);
    });
    container.addEventListener("click", e => {
      this.destroy();
    });

    let inner = document.createElement("div");
    inner.className = "inner";
    inner.style.cssText = `
      display: inline-block;
      margin: 10vh 25px auto 25px;
      overflow: auto;
    `;
    inner.addEventListener("click", e => {
      e.stopPropagation();
    });

    let closer = document.createElement("button");
    let x = document.createTextNode("+");
    closer.append(x);
    closer.className = "dialogue-close";
    closer.style.cssText = `
      font-size: 2.2rem;
      line-height: 1.2rem;
      padding: 5px;
      position: absolute;
      right: 5px;
      top: 5px;
      transform-origin: top;
      transform: rotate(45deg);
    `;
    closer.addEventListener("click", e => this.destroy());
    closer.setAttribute("aria-label", _("close this infomation box"));

    // Add to DOM
    inner.appendChild(closer);
    inner.appendChild(elem);
    container.appendChild(inner);
    this.parent.appendChild(container);

    // Set the rtnfocus, then focus inside dialogue.
    this.rtnfocus = document.activeElement;
    inner.focus();

    this.handler = new Listener(this.id);

    document.addEventListener(`keyup`, this.handler[this.id], false);
  }
}
