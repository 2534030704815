import { _ } from "$json/lib/gettext";

const strings = {
  "lang": "nl",
  "Doubleclick to go back to this question":
    "Dubbelklikken om naar deze vraag terug te springen",
  "create a new case": "maak een nieuwe casus aan",
  "new": "nieuw",
  "Delete": "Verwijder",
  "case": "casus",
  "cases": "casus",
  "Last opened": "Laatst geopend",
  "created": "gemaakt",
  "report": "rapport",
  "Sun": "Zo",
  "Mon": "Ma",
  "Tue": "Di",
  "Wed": "Wo",
  "Thu": "Do",
  "Fri": "Vr",
  "Sat": "Za",
  "Jan": "Jan",
  "Feb": "Feb",
  "Mar": "Maart",
  "Apr": "April",
  "May": "Mei",
  "June": "Juni",
  "July": "Juli",
  "Aug": "Aug",
  "Sep": "Sep",
  "Oct": "Okt",
  "Nov": "Nov",
  "Dec": "Dec",
  "press enter to accept": "accepteer met ENTER",
  "filter cases by name": "zoek casus op naam",
  "field--value": "deze waarde",
  "field--date": "deze datum",
  "field--field": "dit veld",
  "field--list": "deze opties",
  "quoted--value": "de waarde “{quotable}”",
  "quoted--date": "de datum “{quotable}”",
  "quoted--field": "het veld “{quotable}”",
  "quoted--list": "de opties in “{quotable}”",
  "Date required":
    "{quotable--init} is verplicht; gebruik het formaat dd - mm - j j j j.",
  "Input required": "{quotable--init} is verplicht",
  "Choice required": "Keuze uit {quotable} is verplicht",
  "Field required": "{quotable--init} is verplicht",
  "Value has to lie between {minimum} and {maximum}":
    "{quotable--init} moet tussen {minimum} en {maximum} liggen",
  "Date has to lie between {minimum} and {maximum}":
    "{quotable--init} moet tussen {minimum} en {maximum} liggen; gebruik het formaat dd-mm-jjjj.",
  "Negative number or zero expected":
    "{quotable--init} moet negatief zijn of nul",
  "Negative number expected": "{quotable--init} moet negatief zijn",
  "Value has to lie below {maximum}":
    "{quotable--init} moet lager zijn dan {maximum+1}",
  "Positive number or zero expected": "{quotable} moet positief zijn of nul",
  "Positive number expected": "{quotable} moet hoger zijn dan nul",
  "Value has to lie above {minimum}":
    "{quotable--init} moet hoger zijn dan {minimum-1}",
  "A date after {maximum} is not allowed":
    "{quotable--init} ná {maximum} is niet toegestaan; gebruik het formaat dd-mm-jjjj.",
  "A date before {minimum} is not allowed":
    "{quotable--init} vóór {minimum} is niet toegestaan; gebruik het formaat dd-mm-jjjj.",
  "Invalid date":
    "{quotable--init} is ongeldig; gebruik het formaat dd-mm-jjjj.",
  "Invalid number": "{quotable--init} is geen getal",
  "Text length exceeds the maximum of {maxlength} characters":
    "{quotable--init} mag maximaal {maxlength} karakters bevatten",
  "click for more...": "klik voor meer...",
  " (click to open link)": " (klik om link te openen)",
  "Are you sure? This will reset all values.":
    "Weet u het zeker? Dit zal alle ingevulde velden leegmaken.",
  "Yesterday": "Gisteren",
  "No valid session": "Ongeldige gebruikersnaam of wachtwoord",
  "Maximum allowed characters: {0}": "Maximaal {0} karakters toegestaan",
  "No interfaces to show":
    "Geen zichtbare elementen (vragen, tekstvelden) gedefinieerd",
  // feedback
  "Feedback": "Feedback",
  "describe the problem": "omschrijf het probleem",
  "Please do not collate issues.": "Een melding per keer!",
  "Please send only feedback about the currently visible question or questions; information about these will be sent back to the developers.":
    "Geef alleen feedback over de vraag of vragen die je op dit moment op je scherm ziet; informatie over die vragen wordt namelijk meegezonden naar de ontwikkelaars.",
  "send": "verstuur",
  "Thanks for your feedback!": "Bedankt voor uw melding!",
  "Interface has failed (probably a failing SOAP or database connection)":
    "Interface gefaald (waarschijnlijk een falende SOAP- of databasekoppeling)",
  "Choose...": "Kies...",
  "Choose or type...": "Kies of typ...",
  "cUMWrongUserNamePassword": "Ongeldige gebruikersnaam of wachtwoord",
  "cWebCaseIsRunning":
    "Casus laden is niet gelukt; deze casus wordt al gedraaid in een andere sessie",
  "The model you are trying to open does not exist":
    "Het beslismodel dat u probeert te openen bestaat niet",
  "cWebIllegalAccess": "Je mag deze casus niet starten of openen.",
  "You may now safely close this window": "U kunt het venster nu sluiten",
  "user name": "gebruikersnaam",
  "password": "wachtwoord",
  "Your models": "Uw modellen",
  "Your sessions": "Uw sessies",
  "log in": "inloggen",
  "load session": "Lees uw gegevens in",
  "load": "inlezen",
  "Choose the session file you saved earlier":
    "Kies het gegevensbestand dat u de vorige keer hebt opgeslagen",
  "Error loading session (wrong data)":
    "Inlezen gegevens mislukt (verkeerde gegevens).",
  "Please login": "Gelieve in te loggen",
  "open": "open",
  "copy": "kopie",
  'You have no tickets left for model "{0}"':
    'Uw strippenkaart voor model "{0}" is op',
  "__digitgroupingchar__": ".",
  "__radixpoint__": ",",
  "__digitgroupingrules__": [3],
  "attachments": "bijlagen",
  "your documents": "uw documenten",
  "saved sessions": "bewaarde sessies",
  "We are currently updating. Please try again later.":
    "Er is momenteel een update aan de gang, probeert u het binnenkort nogmaals.",
  "case name": "casusnaam",
  "dateplaceholder": "dd - mm - j j j j",
  "Error: No response from server, server probably down":
    "Fout: Server is tijdelijk buiten gebruik. U moet zich opnieuw aanmelden.",
  "Session has timed out. Please log in again.":
    "De sessie is afgelopen. U moet zich opnieuw aanmelden.",
  "first {0} results": "eerste {0} resultaten",
  "previous {0} results": "vorige {0} resultaten",
  "next {0} results": "volgende {0} resultaten",
  "last {0} results": "laatste {0} resultaten",
  "results {0} to {1} from {2}": "resultaten {0} tot {1} van {2}",
  "add row": "Voeg rij toe",
  "insert row": "voeg rij in",
  "delete row": "verwijder rij",
  "There are errors, please double check your answers.":
    "Er zijn nog fouten, gelieve uw antwoorden nogmaals na te gaan.",
  "cancel": "annuleer",
  "Something went wrong; see the server log for more information.":
    "Er is iets misgegaan; bekijk de server log voor meer informatie",
  "There does not seem to be an open session.":
    "Er lijkt geen lopende sessie te zijn."
};

_.set(strings);

export { strings };
