import { conf } from "$json/lib/conf";
import { escaped as esc } from "$json/lib/escape";
/* seo-keywords-description:
 *
 * When model is loaded, client-side
 * JS will manually change the meta
 * description and keywords to be
 * specific to each model, for SEO.
 *
 * NB Dependencies:
 *
 * If a theme is going to use this
 * plugin, it must supply an SEO
 * object under "arbitrary" in the
 * conf.json .

e.g:
"seo": {
  "modelname": {
    "description" : "[model description]",
    "keywords"    : "[model keywords]"
  }
}

 * Author: Tim Bauwens
 * Copyright 2017 Berkeley Bridge
 *
 */

/* global $ */
$(document).on("bb:postHandleData", function (event, data) {
  if (data && data.groups) {
    var SEO = conf.arbitrary.seo;
    if (SEO.length < 1 || !SEO[data.modelname]) {
      useDefault();
    } else {
      var description = SEO[data.modelname]["description"];
      var keywords = SEO[data.modelname]["keywords"];
      var title = data.modeldescription;
      if (description !== undefined && keywords !== undefined) {
        try {
          $("meta[name=description]").remove();
          $("head").append(
            esc`<meta name="description" content="${description}">`
          );
          $("meta[name=keywords]").remove();
          $("head").append(esc`<meta name="keywords" content="${keywords}">`);
          $('meta[itemprop="name"]').remove();
          $("head").append(esc`<meta itemprop="name" content="${title}">`);
          $('meta[name="twitter:title"]').remove();
          $("head").append(esc`<meta name="twitter:title" content="${title}">`);
          $('meta[property="og:title"]').remove();
          $("head").append(esc`<meta property="og:title" content="${title}">`);
          $('meta[property="og:url"]').remove();
          $("head").append(
            esc`<meta property="og:url" content="${location.href}">`
          );
          $('meta[itemprop="description"]').remove();
          $("head").append(
            esc`<meta itemprop="description" content="${description}">`
          );
          $('meta[name="twitter:description"]').remove();
          $("head").append(
            esc`<meta name="twitter:description" content="${description}">`
          );
          $('meta[property="og:description"]').remove();
          $("head").append(
            esc`<meta property="og:description" content="${description}">`
          );
        } catch (e) {
          useDefault();
        }
      } else {
        useDefault();
      }
    }
  }
  function useDefault() {
    var description = data.modeldescription;
    if (description !== undefined) {
      try {
        $("meta[name=description]").remove();
        $("meta[name=keywords]").remove();
        $("head").append(
          esc`<meta name="description" content="${description}">`
        );
      } catch (e) {}
    }
  }
});
