/* info-inline:
 *
 * Render info below pertaining questionlabelgroup.
 *
 * Also showdown its content
 *
 * @dependencies: mustache, array._groupby
 * @optdependencies: showdown
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
import Mustache from "$mustache";
import { converter } from "$md-converter";
import { escapeHTML } from "$json/lib/escape";
import { gt, _ } from "$json";
import { pathOr } from "$json/lib/functional";
import { conf } from "$json/lib/conf";

import { computePosition, flip, offset, shift } from "@floating-ui/dom";

(function ($, win, doc, nf) {
  var translations = {
    "more info about this question": {
      nl: "meer informatie over deze vraag",
      fr: "plus d'information sur cette question",
      de: "mehr Informazionen über diese Frage",
      ru: "больше информации по этому вопросу",
      pt: "mais informações sobre esta questão",
      es: "más información sobre esta cuestión"
    },
    "close more info about this question": {
      nl: "sluiten meer informatie over deze vraag",
      fr: "fermer plus d'information sur cette question",
      de: "abschließen mehr Informazionen über diese Frage",
      ru: "всклучить больше информации по этому вопросу",
      pt: "fechar mais informações sobre esta questão",
      es: "cerrar más información sobre esta cuestión"
    }
  };

  const buttonplacement = pathOr("afterBegin", [
    "arbitrary",
    "info-inline",
    "buttonplacement"
  ]);
  const floating = pathOr(false, ["arbitrary", "info-inline", "floating"]);
  const blockafterlabel = pathOr(false, [
    "arbitrary",
    "info-inline",
    "blockafterlabel"
  ]);

  $(function () {
    gt.addTranslations(translations);
  });

  /**
   * @return unique integer
   */
  var genid = (function () {
    var id = 0;
    return function genid() {
      return id++;
    };
  })();

  $(function () {
    Mustache.tags = ["[[", "]]"];
    var tmpl = $("#bb-p-info-inline-template").html();

    if (typeof tmpl === "undefined") {
      console.info("info-inline.js: No template found, using default");
      tmpl = `[[#sources]]
      <li class="bb-p-info-inline_item">
        [[#external]]
        <a target="_blank" href="[[content]]">[[description]]</a>
        [[/external]]
        [[^external]]
        [[#multiple]]
        <h5 class="bb-p-info-inline_item_heading">[[description]]</h5>
        [[/multiple]]
        <div>[[&content]]</div>
        [[/external]]
      </li>
      [[/sources]]`;
    }

    $(doc).on("bb:postHandleData", function (event, data) {
      if (data && data.informationsources) {
        var info = data.informationsources.filter(interesting);
        info = info._groupby(function (src) {
          return src.groupguid;
        });
        info.map(associateEltWithFirst);
        info.map(render);
      }
    });

    function prepareSource(source) {
      return $.extend({}, source, {
        content: source.isurl
          ? source.content
          : converter.makeHtml(escapeHTML(source.content)) || source.content
      });
    }

    function getQuestionLabelGroup(node) {
      var klass;
      while (
        ((node = node.parentElement),
        (klass = node && node.getAttribute("class")),
        node &&
          (!klass || klass.split(" ").indexOf("bb-questionlabelgroup") === -1))
      );
      return node;
    }

    function interesting(src) {
      return true || src.selected;
    }

    function associateEltWithFirst(srcs) {
      var src = srcs[0];
      var elts = document.querySelectorAll('[data-id="' + src.belongsto + '"]');
      if (elts)
        src.widgets = [...elts].map(elt => getQuestionLabelGroup(elt) || elt);
    }

    function getMatchingChildOrSelf(elt, match) {
      var tw = document.createTreeWalker(
        elt,
        nf.SHOW_ELEMENT,
        matcher(match),
        false
      );
      tw.nextNode();
      return tw.currentNode;
    }

    function render(srcs) {
      var src = srcs[0],
        multiple = srcs.length > 1;
      if (!src.widgets) return;
      for (let widget of src.widgets) {
        let container = widget.nextElementSibling,
          infogroups,
          id,
          contents = Mustache.render(tmpl, {
            sources: srcs.map(function (src) {
              return prepareSource(src);
            }),
            multiple: multiple
          });

        if (!container || container.className !== "bb-p-info-inline-block") {
          id = "bb-p-info-inline-" + genid();
          const button = document.createElement("button");
          button.setAttribute("aria-controls", id);
          button.setAttribute("tabindex", 0);
          button.setAttribute("aria-expanded", false);
          button.classList.add("bb-p-info-inline-link");
          const span = document.createElement("span");
          span.classList.add("a-offscreen");
          span.innerText = _("more info about this question");
          button.appendChild(span);
          const placement = buttonplacement(conf);
          const ref =
            (placement.endsWith("label") &&
              widget.querySelector('[data-type="label"]')) ||
            widget;
          ref.insertAdjacentElement(placement.replace(/label$/, ""), button);

          var after = blockafterlabel(conf)
            ? getMatchingChildOrSelf(widget, '[data-type="label"]')
            : widget;

          const note = document.createElement("div");
          note.id = id;
          note.setAttribute("role", "note");
          note.setAttribute("data-info-inline-groups", src.groupguid);
          note.classList.add("bb-p-info-inline-block");
          if (floating(conf)) note.classList.add("bb-p-info-floating");
          note.style.display = "none";
          const list = document.createElement("ul");
          list.innerHTML = contents;
          const closeButton = document.createElement("button");
          closeButton.setAttribute("aria-controls", id);
          closeButton.classList.add("bb-p-info-inline-close");
          const closeText = document.createElement("span");
          closeText.classList.add("bb-p-info-inline-close-text");
          closeText.innerText = _("close more info about this question");
          closeButton.appendChild(closeText);
          note.appendChild(list);
          note.appendChild(closeButton);
          after.insertAdjacentElement("afterEnd", note);
        } else {
          infogroups = container.getAttribute("data-info-inline-groups");
          if (infogroups.indexOf(src.groupguid) < -1) {
            container.children[0].insertAdjacentHTML("beforeEnd", contents);
            container.setAttribute(
              "data-info-inline-groups",
              infogroups + src.groupguid
            );
          }
        }
      }
    }

    function toggleInfo() {
      var id = this.getAttribute("aria-controls");
      setInfo(id);
    }

    function removeAgain(group) {
      return function () {
        group.classList.remove("bb-p-info-inline-block-again");
      };
    }

    function setInfo(id, show) {
      var group = document.getElementById(id);
      var hidden = group.style.display === "none";
      if (show === undefined) show = hidden;
      if (show && !hidden) {
        group.classList.add("bb-p-info-inline-block-again");
        var removal = removeAgain(group);
        group.addEventListener("webkitAnimationEnd", removal);
        group.addEventListener("animationend", removal);
        return;
      }
      const button = document.querySelector(
        `.bb-p-info-inline-link[aria-controls="${id}"]`
      );
      button.classList.toggle("bb-p-info-inline-expanded", show);
      button.setAttribute("aria-expanded", show);
      if (floating(conf)) {
        const computedstyle = window.getComputedStyle(group);

        const offsetMain = Number(
          computedstyle.getPropertyValue("--bb-p-info-inline-offset-main") || 10
        );
        const offsetCross = Number(
          computedstyle.getPropertyValue("--bb-p-info-inline-offset-cross") || 0
        );
        const placement =
          computedstyle.getPropertyValue(
            "--bb-p-info-inline-floating-placement"
          ) || "right-end";
        computePosition(button, group, {
          placement,
          middleware: [
            offset({ mainAxis: offsetMain, crossAxis: offsetCross }),
            placement.startsWith("bottom") || placement.startsWith("top")
              ? flip()
              : shift()
          ]
        }).then(({ x, y }) => {
          group.style.left = `${x}px`;
          group.style.top = `${y}px`;
          group.style.position = "absolute";
        });
        $(group).toggle(show);
      } else {
        $(group).slideToggle(show);
        if (show) {
          win.setTimeout(
            group =>
              group.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center"
              }),
            400,
            group
          );
        } else {
          // infobtn is the '?' button, and a good marker of where to scroll back to.
          let infobtn = doc.querySelector(
            `.bb-p-info-inline-link[aria-controls="${id}"]`
          );
          infobtn.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center"
          });
        }
      }
    }

    function matcher(selector) {
      return function (node) {
        return (node.matches || node.msMatchesSelector).bind(node)(selector)
          ? nf.FILTER_ACCEPT
          : nf.FILTER_SKIP;
      };
    }

    $(doc).on("click", 'a[href^="bb:info"]', function (ev) {
      ev.preventDefault();
      var tw = document.createTreeWalker(
        document.body,
        nf.SHOW_ELEMENT,
        matcher(".bb-p-info-inline-link"),
        false
      );
      tw.currentNode = this;
      if (tw.previousNode())
        setInfo(tw.currentNode.getAttribute("aria-controls"), true);
      return false;
    });

    $(doc).on("click", ".bb-p-info-inline-link", toggleInfo);
    $(doc).on("click", ".bb-p-info-inline-close", toggleInfo);
  });
})(jQuery, window, document, window.NodeFilter);
