import { wControl } from "$json/lib/control";
import { registerWidget } from "$json/lib/form-widgets";

(function ($, doc) {
  $(function () {
    $(doc).on("bb:preHandleData", function (event, data, status, req) {
      if (typeof data != "undefined" && data && data.groups)
        data.groups = $.map(data.groups, function (group) {
          group.controls = $.map(group.controls, function (control) {
            if (control.controltype == "grid") {
              if (control["font-class"] == "grid with links 2")
                control.controltype = "ntagrid";
            }
            return control;
          });
          return group;
        });
    });
  });

  /* A Collapsable Grid with Comment Fields
   *
   */
  var NtaGrid = {
    name: "ntagrid",
    tagName: "div",
    attribs: () => ({}),
    render: function (control, $widget, group) {
      $.each(control.value, function (i, row) {
        var $row = $('<div class="bb-collapsable bb-collapsed"></div>'),
          collapseid = "collapsible-" + control.id + "-" + i,
          $head = $(
            '<h3 tabindex="0" aria-controls="' +
              collapseid +
              '" aria-expanded="false" class="bb-uncollapsable bb-collapser"></h3>'
          ),
          $wrap = $(
            '<div id="' +
              collapseid +
              '" class="bb-collapsed-content" style="display:none" aria-hidden="true"></div>'
          ),
          $body = $("<table></table>");
        $widget.append($row);
        $row.append($head);
        $row.append($wrap);
        $wrap.append($body);

        $.each(row, function (ii, cell) {
          var $appender,
            $item,
            value = $.isPlainObject(cell) ? cell.value : cell;
          if (ii === 0) {
            $appender = $head;
          } else {
            if (ii === 1) {
              $item = $(
                '<tr><td rowspan="2" class="colname">Toelichting</td><td class="value"></td></tr>'
              );
            } else if (ii === 2) {
              $item = $('<tr><td class="value"></td></tr>');
            } else if (ii === 3) {
              $item = $(
                '<tr><td class="colname">Bron</td><td class="value"></td></tr>'
              );
            } else {
              $item = $(
                '<tr><td class="colname"></td><td class="value"></td></tr>'
              );
            }
            $body.append($item);
            $appender = $item.find(".value");
          }
          var column = control.columns[ii];
          wControl(
            $.extend(
              {},
              column,
              { id: control.id + "-" + i + "-" + ii },
              $.isPlainObject(cell) ? cell : { value: cell }
            ),
            group,
            $appender //append hereto
          );
        });
      });
      return $widget;
    }
  };

  registerWidget(NtaGrid);

  $(doc).on("bb:collapsing", ".bb-collapsable", function () {
    var $this = $(this),
      state = $this.hasClass("bb-collapsed");
    $this.find("[aria-expanded]").attr("aria-expanded", !state);

    $this
      .find(".bb-collapsed-content")
      .slideToggle(500)
      .attr("aria-hidden", state);
  });
})(jQuery, document);
