import { clearMenu } from "./clearmenu.js";
import { state } from "$json/lib/store";
import Dialogue from "$Dialogue";

const show = new Dialogue();
const done = new Event("dmi-complete");

function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export function createMenu() {
  clearMenu();
  let items = state.has("p-dynamic-menu") ? state.get("p-dynamic-menu") : {};

  function showtime(e) {
    let info = document.createElement("div");
    let html = items[e.target.getAttribute("data-textid")];
    info.innerHTML = htmlDecode(html);
    show.render(info);
  }

  Object.keys(items).forEach(title => {
    let item = document.createElement("li");
    item.className = "p-dynamic-menu-item";
    let button = document.createElement("button");
    button.innerText = title;
    button.setAttribute("data-textid", title);
    item.append(button);
    try {
      let menuItem = item.cloneNode(true);
      menuItem.querySelector("button").addEventListener("click", showtime);
      document.getElementById("p-dynamic-menu").append(menuItem);
      // Support class based for duplicate menus.
      document.querySelectorAll(".p-dynamic-menu").forEach(menu => {
        let menuItem = item.cloneNode(true);
        menuItem.querySelector("button").addEventListener("click", showtime);
        menu.append(menuItem);
      });
    } catch (err) {
      window.console &&
        window.console.warn(`
        Seems like the layer doesn't support dynamic-menu...
        Please make sure that the top menu (UL) has [id|class]="p-dynamic-menu"
      `);
      window.console && window.console.warn(err);
    }
  });
  document.dispatchEvent(done);
}
