/*
  has-no-next-meta:

  Toggle data.hasnext when metadata
  prop 'no-next' is present.

  Copyright: Berkeley Bridge 2021
  Author: Tim Bauwens

 */

import { getmetadata } from "$json/lib/getmetadata";

((doc, $) => {
  $(doc).on("bb:preHandleData", (e, data) => {
    if (data && data.groups) {
      const md = getmetadata(data).props;
      data.hasnext = data.hasnext ? !md.has("no-next") : data.hasnext;
    }
  });
})(document, jQuery);
