import { _, bb } from "$json";
/* savecase:
 *
 * Download and upload cases
 *
 * Author: Niels Giesen
 * Copyright 2013 Berkeley Bridge
 *
 */
(function ($, document) {
  $(function () {
    var $doc = $(document),
      $iframe = $(
        '<iframe id="bb-p-uploadert" style="display: none" name="bb-p-uploadert"></iframe>'
      ),
      $form = $(
        '<div class="bb-p-loadcasemask"></div>' +
          '<form id="bb-p-loadcaseform"' +
          '      target="bb-p-uploadert" ' +
          '      action="loadcase?fmt=json"' +
          '      accept="text/plain"' +
          '      enctype="multipart/form-data" method="post">' +
          '  <button type="button" class="bb-p-loadcaseform-close">&#10005;</button>' +
          "  <div>" +
          "    <h1>Laad Sessie</h1>" +
          '    <input type="hidden" name="uniqueid" />' +
          '    <input type="hidden" name="dbname" />' +
          '    <input type="hidden" name="sessionid" />' +
          '    <div class="bb-p-loadcaseform-input-file-wrapper">' +
          '     <input type="file" name="case" required="required" id="bb-loadcasefile" accept=".cas"/>' +
          "    </div>" +
          "    <p>" +
          _("Choose the session file you saved earlier") +
          "</p>" +
          '    <input name="add" type="submit" class="submit" value="' +
          _("load") +
          '" />' +
          "  </div>" +
          "</form>" +
          ""
      );
    // Memorize state vars
    var uid, sid, db;

    $iframe.appendTo("body").on("load", function () {
      var text = $(this.contentDocument.body).text();
      var gotJSON = true;
      try {
        var data = JSON.parse(text);
      } catch (e) {
        gotJSON = false;
      } finally {
        if (gotJSON) {
          $doc
            .trigger("bb:preHandleData", data)
            .trigger("bb:handleData", data)
            .trigger("bb:postHandleData", data);
        }
      }
    });

    $form
      .appendTo("body")
      .find("[type=submit]")
      .on("click", function () {
        $(".progress").fadeIn();
        bb.Mode.set("busy");
      })
      .end()
      .find("[type=file]")
      .on("change", function () {
        $form.find("input[type=submit]").trigger("focus");
      });

    $("#bb-p-loadcaseform").on("submit", function () {
      $("#bb-p-loadcaseform,.bb-p-loadcasemask").hide();
    });
    $("button.bb-p-loadcaseform-close").on("click", function () {
      $("#bb-p-loadcaseform,.bb-p-loadcasemask").hide();
    });
    $(".bb-p-loadcasemask").on("click", function () {
      $("#bb-p-loadcaseform,.bb-p-loadcasemask").fadeOut(200);
    });

    // Listen on the 'load button' that may be anywhere on the page
    $("[data-rel=load]")
      .on("keydown", function (ev) {
        if (ev.keyCode === 13) {
          // Dispatch to click handler on ENTER.
          $(this).trigger("click");
          return false;
        }
        return true;
      })
      .on("click", function () {
        $("#bb-p-loadcaseform,.bb-p-loadcasemask").fadeIn(200);
        $("#bb-loadcasefile")
          .focus()
          .on("keydown", function (ev) {
            if (ev.keyCode === 27) {
              // ESC key triggered, close the loadcase form.
              $("#bb-p-loadcaseform,.bb-p-loadcasemask").fadeOut(200);
            }
          });
        return;
      });

    $doc.on("bb:postHandleData", function (event, data) {
      if (data && data.uniqueid && data.sessionid && data.dbname) {
        if (
          uid !== data.uniqueid ||
          sid !== data.sessionid ||
          db !== data.dbname
        ) {
          uid = data.uniqueid;
          sid = data.sessionid;
          db = data.dbname;
          $("a[data-rel=save]").attr({
            href:
              "savecase?" +
              $.param({
                uniqueid: data.uniqueid,
                sessionid: data.sessionid,
                dbname: data.dbname
              }),
            download:
              data.modelname +
              "." +
              data.sessionid +
              $.datepicker.formatDate("yymmdd", new Date()) +
              ".cas"
          });

          $form.find("input[type=hidden]").each(function () {
            var val = bb.getVar($(this).attr("name"));
            if (val) $(this).val(val);
          });
        }
      }
    });
    function escapeToggle(force = true) {
      let hints = document.querySelectorAll(".bb-p-savecase-hint");
      hints.forEach(hint => {
        hint.classList.toggle("escaped", force);
      });
    }
    // Additional esc key trap to hide hover/focus tips
    document.addEventListener("keydown", function (event) {
      if (event.key === "Escape") {
        escapeToggle(true);
        window.setTimeout(escapeToggle, 5e3, false);
      }
    });
  });
})(jQuery, document);
