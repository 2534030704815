/* rijksoverheidshuisstijlfonts:
 *
 * Supply Rijksoverheidshuisstijl fonts + definitions
 *
 * Author: Niels Giesen
 * Copyright 2017 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  $(function () {});
})(jQuery, window, document);
