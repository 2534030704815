/* global $ */
import "./a11y-user-detect.js";
import { _ } from "./gettext.js";

/*** A11y BEGIN ***/

const isLiveRegion = area =>
  area.role === "alert" ||
  ["assertive", "polite"].includes(area.getAttribute("aria-live"));

const LIVE_REGION_SELECTOR = "#a-logdiv";

const _liveRegion = document.querySelector(LIVE_REGION_SELECTOR);

if (!_liveRegion) {
  console.warn(
    `Live errors and such will not be announced to screen readers, as there is no ${LIVE_REGION_SELECTOR} at pageLoad!`
  );
}

if (_liveRegion && !isLiveRegion(_liveRegion)) {
  console.warn(`${LIVE_REGION_SELECTOR} is not actually an aria-live region.`);
}

const liveRegion = _liveRegion;

export const A11y = {
  log: function (message, html = false) {
    if (!liveRegion) {
      return;
    }
    if (liveRegion && !liveRegion.isConnected) {
      console.warn(`${LIVE_REGION_SELECTOR} is not connected`);
      return;
    }
    if (html) {
      liveRegion.innerHTML = message;
    } else liveRegion.textContent = message;
    const textContent = liveRegion.textContent;
    setTimeout(() => {
      if (liveRegion.textContent === textContent) liveRegion.textContent = "";
    }, 500);
  }
};

/**
 * Datepicker a11y enhancements
 */
export const observeDatepickers = (function () {
  var init = false;
  return function initDateObservance() {
    var picker, observer;

    if (init || !("MutationObserver" in window)) {
      init = true;
      return;
    }

    $(document).on("blur", '[data-type="datetimepicker"]', function () {
      A11y.log("");
    });

    function observation(records, instance) {
      try {
        var infocus =
          document.activeElement.className.split(" ").indexOf("hasDatepicker") >
          -1;
      } catch (e) {
        // there mayn't be an activeElement, in which case className is undefined.
      }
      if (infocus) {
        var message = [
          $(".ui-state-hover").text(),
          $(".ui-datepicker-month [selected]").text(),
          $(".ui-datepicker-year [selected]").text()
        ].join(" ");
        A11y.log(message + ", " + _("press enter to accept"));
      }
    }

    picker = document.getElementById("ui-datepicker-div");

    if (picker) {
      observer = new window.MutationObserver(observation);
      observer.observe(picker, { attributes: true });
      init = true;
    }
  };
})();
