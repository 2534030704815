import { bb } from "$json";
/* Logo switch:
 *
 * Change logo according to modelname.
 * A bit excessive for just one model,
 * but more flexible for future models
 * who may also require separate logos.
 *
 * Author: Tim Bauwens
 * Copyright 2017 Berkeley Bridge
 *
 * NB: Dependancies
 *
 * Any configuration using this theme
 * and plugin combo, must use and alias
 * and cannot be named rijks-ng.
 * The reason is because if it's named
 * rijks-ng, the baker will strip the alias
 * and the plugin needs to have a valid
 * conf.alias at runtime.
 */
(function ($, win, doc) {
  var _mapping = {
    rekenhulplkv: {
      src: "custom/" + bb.conf.alias + "/images/logo-szw.svg?v=2",
      alt: "Logo Ministerie van Sociale Zaken en Werkgelegenheid",
      href: "https://www.rijksoverheid.nl/ministeries/ministerie-van-sociale-zaken-en-werkgelegenheid/"
    },
    premiekortingen: {
      src: "custom/" + bb.conf.alias + "/images/logo-szw.svg?v=2",
      alt: "Logo Ministerie van Sociale Zaken en Werkgelegenheid",
      href: "https://www.rijksoverheid.nl/ministeries/ministerie-van-sociale-zaken-en-werkgelegenheid/"
    },
    quotumcalculator: {
      src: "custom/" + bb.conf.alias + "/images/logo-szw.svg?v=2",
      alt: "Logo Ministerie van Sociale Zaken en Werkgelegenheid",
      href: "https://www.rijksoverheid.nl/ministeries/ministerie-van-sociale-zaken-en-werkgelegenheid/"
    },
    lkv: {
      src: "custom/" + bb.conf.alias + "/images/logo-szw.svg?v=2",
      alt: "Logo Ministerie van Sociale Zaken en Werkgelegenheid",
      href: "https://www.rijksoverheid.nl/ministeries/ministerie-van-sociale-zaken-en-werkgelegenheid/"
    },
    rie: {
      src: "custom/" + bb.conf.alias + "/images/logo-szw.svg?v=2",
      alt: "Logo Ministerie van Sociale Zaken en Werkgelegenheid",
      href: "https://www.rijksoverheid.nl/ministeries/ministerie-van-sociale-zaken-en-werkgelegenheid/"
    },
    noChange: {
      src: "custom/" + bb.conf.alias + "/images/logo-rvo.svg?v=2",
      alt: "Logo Rijksdienst voor Ondernemend Nederland",
      href: "https://www.rvo.nl/"
    }
  };

  $(function () {
    var $logo = $(".logo-main");
    var $linkText = $(".link-text");
    var $logoLink = $(".logo-link");
    $(doc).on("bb:postHandleData", function (event, data) {
      if (data && data.modelname) {
        var path = _mapping[data.modelname];
        if (path) {
          $logo.attr("src", path.src);
          $logo.attr("alt", path.alt);
          $linkText.text(path.alt);
          $logoLink.attr("href", path.href);
        } else {
          $logo.attr("src", _mapping.noChange.src);
          $logo.attr("alt", _mapping.noChange.alt);
          $linkText.text(_mapping.noChange.alt);
          $logoLink.attr("href", _mapping.noChange.href);
        }
      }
    });
  });
})(jQuery, window, document);
