import { bb } from "$json";
import { getSetting, getProxyString } from "$proxymap";
import { notify } from "$json/lib/notify";

/* path-to-modelname:
 *
 * Open modelname based on path.
 *
 * This allows bookmarking and nice links
 *
 * Author: Niels Giesen
 * Copyright 2016, 2018 Berkeley Bridge
 *
 */
(function ($, window, doc, location) {
  var props = bb.propFinder(bb.conf, "arbitrary.path-to-modelname");

  if (!location.origin) {
    location.origin =
      location.protocol +
      "//" +
      location.hostname +
      (location.port ? ":" + location.port : "");
  }

  function open() {
    /*
      Calling 'getSetting' with empty key results in
      full proxymap object. With this we can loop
      through all additional props as query params.
      Not only modelname.
    */
    // var modelname = getSetting("modelname");
    var params = getSetting("", {});
    var postdata = {
      url: "open",
      data: {
        fmt: "json"
      }
    };
    Object.keys(params).forEach(param => {
      postdata.data[param] = params[param];
    });

    if (postdata.data.modelname) {
      bb.ajax.post(postdata);
      document.body.setAttribute("data-p-path-to-modelname", getProxyString());
    } else {
      notify({ html: true, keepalive: true }, props("notfoundthen.message"));
    }
  }

  function getState() {
    return (
      window.history &&
      window.history.pushState &&
      (window.history.state ||
        (window.sessionStorage &&
          "JSON" in window &&
          JSON.parse(window.sessionStorage.getItem("state"))))
    );
  }

  function directory(loc) {
    return loc.origin + loc.pathname.replace(/[^/]+$/, "");
  }

  function hasOurState() {
    var state = getState();
    if (!state) return false;
    else return directory(location) === state.directory;
  }

  if (
    (location.pathname.match(/\/$/) || location.search.length === 0) &&
    !hasOurState()
  ) {
    $(open);
  } else {
    $(doc).one("bb:postHandleData", function (event, data) {
      if (
        (data && data.error && data.error.summary) ||
        (data && data.groups && !data.groups.length)
      ) {
        open();
      }
    });
  }
})(jQuery, window, document, window.location);
