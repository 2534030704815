/* share:
 *
 * Share deeplink to model
 *
 * Author:
 * Copyright 2015 Berkeley Bridge
 *
 * Adapted for RIJKS, 2017
 *
 * NB Dependencies:
 *
 * Owing to the way that social sites
 * scrape for content on sharing, it
 * is advised to place the following
 * meta tags into the template:
 *
 <!-- Update the html tag to include the itemscope and itemtype attributes. -->
 <html itemscope itemtype="http://schema.org/WebSite">

 <!-- Schema.org markup for Google+ -->
 <meta itemprop="name" content="The Name or Title Here">
 <meta itemprop="description" content="This is the page description">
 <meta itemprop="image" content="URI to PNG or JPG">
 <meta property="accessibilityFeature" content="alternativeText"/>
 <meta property="accessibilityAPI" content="ARIA"/>
 <meta property="accessibilityControl" content="fullKeyboardControl"/>
 <meta property="accessibilityHazard" content="none"/>

 <!-- Twitter Card data -->
 <meta name="twitter:card" content="summary_large_image">
 <meta name="twitter:title" content="Page Title">
 <meta name="twitter:description" content="Page description less than 200 characters">
 <meta name="twitter:image:src" content="URI to PNG or JPG 800 x 418 pixels">

 <!-- Open Graph data -->
 <meta property="og:title" content="Title Here" />
 <meta property="og:type" content="website" />
 <meta property="og:url" content="http://www.example.com/" />
 <meta property="og:image" content="URI to PNG or JPG" />
 <meta property="og:description" content="Description Here" />
 <meta property="og:site_name" content="Site Name, i.e. Moz" />

 NB Test all platforms:

 Twitter Validation Tool
 https://dev.twitter.com/docs/cards/validation/validator

 Facebook Debugger
 https://developers.facebook.com/tools/debug

 C. Google Structured Data Testing Tool
 http://www.google.com/webmasters/tools/richsnippets

 */

(function ($, win, doc) {
  var _link, _modelname, _description;

  /*
    Before we do anything else, let's set the og:url,
    so scrapers can grab the right meta data.
  */
  $(doc).on("bb:postHandleData", function (e, data) {
    if (data && data.groups) {
      $('head meta[property="og:url"]').attr("content", window.location.href);
    }
  });

  function windowspecs() {
    var w = 480,
      h = 380,
      x = Number((win.screen.width - w) / 2),
      y = Number((win.screen.height - h) / 2);
    return (
      "width=" +
      w +
      ",height=" +
      h +
      ",left=" +
      x +
      ",top=" +
      y +
      ",scrollbars=no,toolbar=no,resizable=yes,status=0"
    );
  }

  function getlink(modelname) {
    /*
      First check if this is a redirected URL,
      since it could then be sourced from a
      non- bb.html layer, so sharing with the
      bb.html might end in a wrong presentation
      layer being shared. As is the case in
      many Regelhulpen.
    */
    var lastChar = win.location.href.substr(-1);
    if (lastChar === "/") {
      return window.location.href + "?modelname=" + modelname;
    } else {
      return (
        win.location.href +
        // Add bb.html to trailing slash
        (/\/$/.test(win.location.pathname) ? "bb.html" : "") +
        "?modelname=" +
        modelname
      );
    }
  }

  $(function () {
    $(doc).on("bb:postHandleData", function (event, data) {
      if (data && data.modelname) {
        _modelname = data.modelname;
        _description = data.modeldescription;
        _link = getlink(_modelname);
        $(".bb-p-share-link").each(function () {
          $(this)
            .val(getlink(_modelname))
            .css("width", $(this).val().length * 6.75 + "px");
        });
      }
    });
  });

  $(doc)
    .on("click", ".bb-p-share", function (ev) {
      $(this).next(".bb-p-share-link").toggleClass("hidden");
      return false;
    })
    .on("click", ".bb-p-share-facebook", function (ev) {
      var f = "https://www.facebook.com/share",
        p =
          ".php?src=bm&v=4&i=1434464511&u=" +
          encodeURIComponent(_link) +
          "&t=" +
          encodeURIComponent(_description);
      if (!window.open(f + "r" + p, "Share with Facebook", windowspecs()))
        win.location.href = f + p;
    })
    .on("click", ".bb-p-share-gplus", function (ev) {
      var f = "https://plusone.google.com/_/+1/confirm?hl=en&url=",
        p =
          encodeURIComponent(_link) +
          "&title=" +
          encodeURIComponent(_description);
      if (!win.open(f + p, "Share with Google", windowspecs()))
        win.location.href = f + p;
    })
    .on("click", ".bb-p-share-linkedin", function (ev) {
      var f = "http://www.linkedin.com/shareArticle?mini=true&url=",
        p =
          encodeURIComponent(_link) +
          "&title=" +
          encodeURIComponent(_description) +
          "&source=" +
          encodeURIComponent(win.location.host);
      if (!win.open(f + p, "Share with LinkedIn", windowspecs()))
        win.location.href = f + p;
    })
    .on("click", ".bb-p-share-twitter", function (ev) {
      var f = "http://twitter.com/share?url=",
        p =
          encodeURIComponent(_link) +
          "&text=" +
          encodeURIComponent(_description);
      if (!win.open(f + p, "Share with Twitter", windowspecs()))
        win.location.href = f + p;
    });

  $(doc).on("bb:postHandleData", function (event, data) {
    if (data && data.groups && data.groups.length) {
      var RESULT_NODES = [
        "gregelhulp.risico_analyse",
        "gregelhulp.niveau",
        "gregelhulp.resultaat",
        "gregelhulp.einde",
        "gregelhulp.resultaat2",
        "gregelhulp.resultaat_tijdelijk",
        "goctrooi.resultaten1",
        "goctrooi.resultaten3",
        "goctrooi.resultaten5",
        "gmodel.resultaat",
        "gmerk.resultaat",
        "gconclusie.conclusie",
        "gregelhulp.lma",
        "gregelhulp.d5_derde_landen_regeling",
        "gregelhulp.z1_advies",
        "gregelhulp.z2_advies",
        "gregelhulp.z3_advies",
        "gregelhulp.z4_advies",
        "gregelhulp.z5_advies",
        "gregelhulp.v1_concl",
        "gregelhulp.v2_concl",
        "gregelhulp.v3_concl",
        "gregelhulp.v4_concl",
        "gregelhulp.v5_concl",
        "gregelhulp.v6_concl",
        "gregelhulp.v7_concl",
        "regelhulp.concl_geenloonheffingen1",
        "regelhulp.concl_geenloonheffingen2",
        "regelhulp.concl_geenloonheffingen3",
        "regelhulp.concl_geenloonheffingen4",
        "regelhulp.concl_fictievedienstbetrekking1",
        "regelhulp.concl_fictievedienstbetrekking2",
        "regelhulp.concl_geenvrijwilligersregeling",
        "regelhulp.concl_vrijwilligersregeling",
        "gregelhulp.concl_geenloonheffingen1",
        "gregelhulp.concl_geenloonheffingen2",
        "gregelhulp.concl_geenloonheffingen3",
        "gregelhulp.concl_geenloonheffingen4",
        "gregelhulp.concl_fictievedienstbetrekking1",
        "gregelhulp.concl_fictievedienstbetrekking2",
        "gregelhulp.concl_geenvrijwilligersregeling",
        "gregelhulp.concl_vrijwilligersregeling",
        "gregelhulp.concl_dienstbetrekking",
        "dna.document",
        "gregelhulp.conclusie_tonnage",
        "gregelhulp.conclusie_zekerheid",
        "gregelhulp.document",
        "gregelhulp.prive"
      ];
      var START_NODES = [
        "gregelhulp.introductie",
        "gvastgoed_transformatie.start",
        "gregelhulp.start",
        "gregelhulp.inleiding",
        "ginleiding.inleiding",
        "dna.start",
        "main.instructie",
        "main.introductie",
        "gintroductie.start",
        "gcalculator.keuze",
        "gvragen.introductie",
        "gregelhulp.v1",
        "ginleiding.inleiding_nl",
        "ginleiding.inleiding_en",
        "regelhulp.introductie"
      ];
      if (
        RESULT_NODES.indexOf(data.groups[data.groups.length - 1].name) >= 0 ||
        START_NODES.indexOf(data.groups[data.groups.length - 1].name) >= 0
      ) {
        // Force the share icons to show;
        $(".bb-p-share-container.desktop").css("margin-left", "0");
      } else {
        // Force the share icons to hide;
        $(".bb-p-share-container.desktop").css("margin-left", "");
      }
    }
  });
})(jQuery, window, document);
