import {
  groupWith,
  map,
  filter,
  pipe,
  path,
  strictUniq,
  lensPath
} from "./functional.js";

const pathToGroup = ["metadata", "group"];
export const pathGroup = path(pathToGroup);
export const lensGroup = lensPath(["metadata", "group"]);

const areOfOneQuestion = (a, b) => {
  if (a.isfor === undefined && b.isfor === undefined) return false;
  return a.isfor === b.id || b.isfor === a.id;
};

export const groupInner = groupWith(areOfOneQuestion);

const InfinityIfMinus1 = num => (num + 1 || Infinity) - 1;
const indexOfOrInfinity = (sub, s) => InfinityIfMinus1(s.indexOf(sub));
export const baseGroup = s => s.substr(0, indexOfOrInfinity(".", s));
export const tailGroup = s => s.substr(indexOfOrInfinity(".", s) + 1);

const belongTogether = (a, b) =>
  areOfOneQuestion(a, b) ||
  (pathGroup(a) &&
    pathGroup(b) &&
    baseGroup(pathGroup(a)) === baseGroup(pathGroup(b)));

export const groupOuter = groupWith(belongTogether);

export const groupClasses = pipe(
  map(path[("metadata", "groupClasses")]),
  filter(Boolean),
  strictUniq
);

export const doGrouping = path(["arbitrary", "core", "form-group", "on"]);
