/*
  dynamic-menu-items.js

  Based on text-interfaces inside models, create
  menu items in the top menu with popups containing
  the content from the model.
*/

import { state } from "$json/lib/store";
import { clearMenu } from "./clearmenu.js";
import { createMenu } from "./createmenu.js";
import { getmetadata } from "$json/lib/getmetadata";

((doc, $) => {
  let render = false;
  var items = {};
  var session = false;

  var escape = document.createElement("textarea");
  function escapeHTML(html) {
    escape.textContent = html;
    return escape.innerHTML;
  }

  $(doc).on("bb:finalHandleData", (e, data) => {
    if (data && data.groups && data.groups.length) {
      if (session && session !== data.sessionid) {
        clearMenu();
        items = {};
      }
      session = data.sessionid;
      const controls = getmetadata(data).controls;
      controls.forEach((props, id) => {
        Object.keys(props).forEach(key => {
          if (key.startsWith("dmi-")) {
            let elem = doc.getElementById(id[0]);
            items[props[key]] = escapeHTML(elem.outerHTML);
            elem.style.display = "none";
            render = true;
          }
        });
      });
    }
    if (render) {
      state.set("p-dynamic-menu", items);
      render = false;
      createMenu();
    }
    if (data && data.models) {
      clearMenu();
      items = {};
    }
    if (data && data.status && data.status === "logout successful") {
      clearMenu();
      items = {};
    }
  });

  $(doc).on("bb:stateChange", e => {
    if (state.has("p-dynamic-menu")) {
      // Only recreate the menu if the state doesn't match what we already have.
      if (
        JSON.stringify(items) !== JSON.stringify(state.get("p-dynamic-menu"))
      ) {
        items = state.get("p-dynamic-menu");
        createMenu();
      }
    }
  });
})(document, jQuery);
