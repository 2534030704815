/*
 * end-tools:
 *
 * Place up to four functions in the
 * top or bottom of bb-q on any node.
 *
 * 1) A download report button
 * 2) An email report widget
 * 3) A print option.
 * 4) A feedback widget.
 *
 * Author: Tim Bauwens
 * Copyright 2020 Berkeley Bridge
 *
 */

import print from "print-js";
import { gt, bb, _ } from "$json";
import { getmetadata } from "../../js/lib/getmetadata";

(($, win, doc) => {
  var translations = {
    "Rapport downloaden": {
      nl: "Rapport downloaden",
      en: "Download report"
    },
    "Verzend rapport via e-mail": {
      nl: "Verzend rapport via e-mail",
      en: "Send report via e-mail"
    },
    "Was dit nuttig?": {
      nl: "Was dit nuttig?",
      en: "Was this useful?"
    },
    "Verzend": {
      nl: "Verzend",
      en: "Send"
    },
    "vul hier uw e-mail in": {
      nl: "vul hier uw e-mail in",
      en: "provide your e-mail here"
    },
    "jouw@email.com": {
      nl: "jouw@email.com",
      en: "your@email.com"
    },
    "E-mail is verzonden...": {
      nl: "E-mail is verzonden.",
      en: "E-mail has been sent."
    },
    "Bedankt voor uw feedback": {
      nl: "Bedankt voor uw feedback",
      en: "Thanks for your feedback"
    },
    "Ja": {
      nl: "Ja",
      en: "Yes"
    },
    "Nee": {
      nl: "Nee",
      en: "No"
    },
    "prepping": {
      nl: "voorbereiden...",
      en: "preparing..."
    },
    "impossible": {
      nl: "niet mogelijk.",
      en: "not posssible."
    }
  };

  gt.addTranslations(translations);

  var metadata,
    emailSent,
    REPORTDOC = "", // Because there could be multiple, but we only handle one.
    SHOWONTOP = "true",
    DESIRED_TOOLS = [];

  $(function () {
    $(doc).on("bb:preHandleData", (event, data) => {
      if (data && data.groups && data.groups.length && data.sessionid) {
        // Check in case metadata has been added or changed
        metadata = getmetadata(data).props;

        if (
          metadata.has("reportdoc") &&
          metadata.get("reportdoc").split(".").pop() === "pdf"
        ) {
          REPORTDOC = metadata.get("reportdoc");
        }
        if (metadata.has("desiredtools")) {
          DESIRED_TOOLS.length = 0;
          metadata
            .get("desiredtools")
            .split("|")
            .forEach(entry => {
              DESIRED_TOOLS.push(entry);
            });
        }
        if (metadata.has("showontop")) {
          SHOWONTOP = metadata.get("showontop");
        } else {
          SHOWONTOP = "true";
        }

        emailSent = emailSent || false;
      }
    });
  });

  $(function () {
    $(doc).on("bb:postHandleData", function (event, data) {
      // Unset mode for hooks
      bb.Mode.unset("hasThreeInOne");
      if (data && data.groups && data.groups.length && data.sessionid) {
        if (metadata.has("end-tools") && metadata.get("end-tools")) {
          // Set mode for hooks
          bb.Mode.set("hasThreeInOne");

          // Hide and reset the support inputs.
          $(".bb-g-endtools-feedback-vote").hide();
          $(".bb-g-endtools-email").hide();
          $(".bbm-endtools-email").val("");

          // Create the container for the functions.
          var $container = $('<div class="bb-p-end-tools-container"></div>');

          if (DESIRED_TOOLS.indexOf("download") > -1 && REPORTDOC.length) {
            // Make a download report button
            var $download = $(
              '<div class="container download-container"></div>'
            );
            $download.append(`
              <a
                data-datatype="Text"
                href="${REPORTDOC}?guid=${data.uniqueid}:${data.dbname}:${
                  data.sessionid
                }"
                target="_blank"
                rel="noopener"
                download="${REPORTDOC}"
                data-type="linklabel">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 512 512"><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"/></svg>
                  ${_("Rapport downloaden")}
              </a>
            `);
            $container.append($download);
          }

          if (DESIRED_TOOLS.indexOf("print") > -1 && REPORTDOC.length) {
            // Make the print button.
            var $print = $(
              '<div id="bb-p-end-tools-print" class="container print-container"></div>'
            );
            var $printbtn = $(`
                <button class="print-button" data-loading-text="${_(
                  "prepping"
                )}" data-impossible-text="${_("impossible")}">
                  <?xml version="1.0" encoding="iso-8859-1"?>
                  <!-- Generator: Adobe Illustrator 17.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  	 viewBox="0 0 407.958 407.958" style="enable-background:new 0 0 407.958 407.958;" xml:space="preserve"
                     width="30px" height="30px">
                    <g>
                    	<g>
                    		<path fill="currentColor" d="M84.979,307.916H33.153C14.873,307.916,0,293.04,0,274.756l0.197-125.688c0-18.274,14.878-33.151,33.166-33.151h27.116
                    			c4.418,0,8,3.582,8,8s-3.582,8-8,8H33.363c-9.465,0-17.166,7.7-17.166,17.164L16,274.768c0,9.45,7.695,17.148,17.153,17.148
                    			h51.826c4.418,0,8,3.582,8,8S89.397,307.916,84.979,307.916z"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M374.607,307.916h-53.961c-4.418,0-8-3.582-8-8s3.582-8,8-8h53.961c9.458,0,17.153-7.698,17.153-17.161l0.197-125.688
                    			c0-9.452-7.695-17.151-17.153-17.151h-29.43c-4.418,0-8-3.582-8-8s3.582-8,8-8h29.43c18.281,0,33.153,14.877,33.153,33.164
                    			l-0.197,125.688C407.76,293.04,392.888,307.916,374.607,307.916z"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M359.906,172H47.854c-4.418,0-8-3.582-8-8s3.582-8,8-8h312.052c4.418,0,8,3.582,8,8S364.325,172,359.906,172z"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M64.042,170.146c-4.418,0-8-3.582-8-8V83.96c0-4.418,3.582-8,8-8h23.101c4.418,0,8,3.582,8,8s-3.582,8-8,8H72.042v70.187
                    			C72.042,166.565,68.46,170.146,64.042,170.146z"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M343.854,170.242c-4.418,0-8-3.582-8-8V92.056h-14.582c-4.418,0-8-3.582-8-8s3.582-8,8-8h22.582c4.418,0,8,3.582,8,8
                    			v78.187C351.854,166.661,348.272,170.242,343.854,170.242z"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M87.979,170.763c-4.418,0-8-3.582-8-8V44.041c0-4.418,3.582-8,8-8h231.906c4.418,0,8,3.582,8,8v111.893
                    			c0,4.418-3.582,8-8,8s-8-3.582-8-8V52.041H95.979v110.722C95.979,167.182,92.397,170.763,87.979,170.763z"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M319.979,371.916H88.062c-4.418,0-8-3.582-8-8V227.791c0-4.418,3.582-8,8-8h231.917c4.418,0,8,3.582,8,8v136.125
                    			C327.979,368.335,324.397,371.916,319.979,371.916z M96.062,355.916h215.917V235.791H96.062V355.916z"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M47.849,204.169c-2.1,0-4.16-0.86-5.65-2.35c-1.49-1.48-2.35-3.55-2.35-5.65c0-2.11,0.86-4.17,2.35-5.66
                    			c1.49-1.49,3.55-2.34,5.65-2.34c2.11,0,4.17,0.85,5.66,2.34c1.49,1.49,2.34,3.55,2.34,5.66c0,2.1-0.85,4.17-2.34,5.65
                    			C52.019,203.309,49.959,204.169,47.849,204.169z"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M343.949,203.919c-2.11,0-4.17-0.86-5.66-2.35c-1.49-1.48-2.34-3.55-2.34-5.65c0-2.11,0.85-4.17,2.34-5.66
                    			c1.49-1.49,3.55-2.34,5.66-2.34c2.11,0,4.17,0.85,5.65,2.34c1.49,1.49,2.35,3.55,2.35,5.66c0,2.1-0.86,4.17-2.35,5.65
                    			C348.119,203.059,346.049,203.919,343.949,203.919z"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M279.976,299.841H127.907c-4.418,0-8-3.582-8-8s3.582-8,8-8h152.069c4.418,0,8,3.582,8,8S284.395,299.841,279.976,299.841
                    			z"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M279.976,331.791h-15.997c-4.418,0-8-3.582-8-8s3.582-8,8-8h15.997c4.418,0,8,3.582,8,8S284.395,331.791,279.976,331.791z
                    			"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M231.812,331.791H127.907c-4.418,0-8-3.582-8-8s3.582-8,8-8h103.905c4.418,0,8,3.582,8,8S236.23,331.791,231.812,331.791z
                    			"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M191.979,267.891h-64.072c-4.418,0-8-3.582-8-8s3.582-8,8-8h64.072c4.418,0,8,3.582,8,8S196.397,267.891,191.979,267.891z
                    			"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M279.976,267.891h-55.831c-4.418,0-8-3.582-8-8s3.582-8,8-8h55.831c4.418,0,8,3.582,8,8S284.395,267.891,279.976,267.891z
                    			"/>
                    	</g>
                    	<g>
                    		<path fill="currentColor" d="M319.979,204.166h-32c-4.418,0-8-3.582-8-8s3.582-8,8-8h32c4.418,0,8,3.582,8,8S324.397,204.166,319.979,204.166z"/>
                    	</g>
                    </g>
                  </svg>
                  Print
                </button>
              `).on("click", e => {
              printJS({
                printable: `${REPORTDOC}?guid=${data.uniqueid}:${data.dbname}:${data.sessionid}`,
                type: "pdf",
                showModal: false
              });
            });
            $print.append($printbtn);
            $container.append($print);
          }

          if (DESIRED_TOOLS.indexOf("email") > -1) {
            // Make the email widget
            var $email = $('<div class="container email-container"></div>');

            if (emailSent) {
              $email.append(
                '<label class="centered">' +
                  _("E-mail is verzonden...") +
                  "</label>"
              );
              emailSent = false;
            } else {
              $email.append(
                `<label class="label--placeholder"><span class="label--text">${_("vul hier uw e-mail in")}</span>
               <input class="email-address ready" type="text" placeholder="${_(
                 "jouw@email.com"
               )}" /></label>`
              ).append(`
                <button class="opt-for-email">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"/>
                  </svg>
                  ${_("Verzend rapport via e-mail")}
                </button>
              `);

              // Handler
              $(doc).off(".end-tools-events", ".opt-for-email");
              $(doc).on(
                "click.end-tools-events",
                ".opt-for-email",
                function (e) {
                  $(".opt-for-email")
                    .html(_("Verzend") + ' <span class="icon">&#8702;</span> ')
                    .attr("class", "email-send bb-next")
                    .prop("disabled", "disabled");
                  $(".email-container").addClass("set");
                  $(".email-address")
                    .removeClass("ready")
                    .addClass("set")
                    .trigger("focus");
                }
              );
              $(doc).off(".end-tools-events", ".email-address");
              $(doc).on(
                "\
                propertychange.end-tools \
                change.end-tools-events \
                click.end-tools-events \
                keyup.end-tools-events \
                input.end-tools-events \
                paste.end-tools-events",
                ".email-address",
                function () {
                  var input = $("input.email-address").val();
                  var regex =
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  if (regex.test(input)) {
                    $("button.email-send")
                      .addClass("validated")
                      .removeAttr("disabled");
                    emailSent = true;
                    $(".bbm-endtools-email").val(input);
                  } else {
                    $("button.email-send")
                      .removeClass("validated")
                      .prop("disabled", "disabled");
                    $(".bbm-endtools-email").val("");
                  }
                }
              );
            }
            $container.append($email);
          }

          if (DESIRED_TOOLS.indexOf("feedback") > -1) {
            // Make the feedback buttons.
            var $feedback = $(
              '<div id="bb-p-end-tools-feedback" class="container feedback-container"></div>'
            );
            $feedback
              .append(
                `
                <button class="feedback-button" data-value="Negatief">
                  <span class="a-offscreen">${_("Nee")}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 512 512"><path fill="currentColor" d="M466.27 286.69C475.04 271.84 480 256 480 236.85c0-44.015-37.218-85.58-85.82-85.58H357.7c4.92-12.81 8.85-28.13 8.85-46.54C366.55 31.936 328.86 0 271.28 0c-61.607 0-58.093 94.933-71.76 108.6-22.747 22.747-49.615 66.447-68.76 83.4H32c-17.673 0-32 14.327-32 32v240c0 17.673 14.327 32 32 32h64c14.893 0 27.408-10.174 30.978-23.95 44.509 1.001 75.06 39.94 177.802 39.94 7.22 0 15.22.01 22.22.01 77.117 0 111.986-39.423 112.94-95.33 13.319-18.425 20.299-43.122 17.34-66.99 9.854-18.452 13.664-40.343 8.99-62.99zm-61.75 53.83c12.56 21.13 1.26 49.41-13.94 57.57 7.7 48.78-17.608 65.9-53.12 65.9h-37.82c-71.639 0-118.029-37.82-171.64-37.82V240h10.92c28.36 0 67.98-70.89 94.54-97.46 28.36-28.36 18.91-75.63 37.82-94.54 47.27 0 47.27 32.98 47.27 56.73 0 39.17-28.36 56.72-28.36 94.54h103.99c21.11 0 37.73 18.91 37.82 37.82.09 18.9-12.82 37.81-22.27 37.81 13.489 14.555 16.371 45.236-5.21 65.62zM88 432c0 13.255-10.745 24-24 24s-24-10.745-24-24 10.745-24 24-24 24 10.745 24 24z"/></svg>
                </button>
              `
              )
              .append("<label>" + _("Was dit nuttig?") + "</label>").append(`
                <button class="feedback-button" data-value="Positief">
                  <span class="a-offscreen">${_("Ja")}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 512 512"><path fill="currentColor" d="M466.27 286.69C475.04 271.84 480 256 480 236.85c0-44.015-37.218-85.58-85.82-85.58H357.7c4.92-12.81 8.85-28.13 8.85-46.54C366.55 31.936 328.86 0 271.28 0c-61.607 0-58.093 94.933-71.76 108.6-22.747 22.747-49.615 66.447-68.76 83.4H32c-17.673 0-32 14.327-32 32v240c0 17.673 14.327 32 32 32h64c14.893 0 27.408-10.174 30.978-23.95 44.509 1.001 75.06 39.94 177.802 39.94 7.22 0 15.22.01 22.22.01 77.117 0 111.986-39.423 112.94-95.33 13.319-18.425 20.299-43.122 17.34-66.99 9.854-18.452 13.664-40.343 8.99-62.99zm-61.75 53.83c12.56 21.13 1.26 49.41-13.94 57.57 7.7 48.78-17.608 65.9-53.12 65.9h-37.82c-71.639 0-118.029-37.82-171.64-37.82V240h10.92c28.36 0 67.98-70.89 94.54-97.46 28.36-28.36 18.91-75.63 37.82-94.54 47.27 0 47.27 32.98 47.27 56.73 0 39.17-28.36 56.72-28.36 94.54h103.99c21.11 0 37.73 18.91 37.82 37.82.09 18.9-12.82 37.81-22.27 37.81 13.489 14.555 16.371 45.236-5.21 65.62zM88 432c0 13.255-10.745 24-24 24s-24-10.745-24-24 10.745-24 24-24 24 10.745 24 24z"/></svg>
                </button>
              `);

            // Handler
            var group;
            if (data && data.userinfo)
              $feedback.data("userinfo", data.userinfo);
            group = data.groups.filter(function (g) {
              return g.current;
            })[0];
            $feedback.data("nodename", group.name);
            if (data && data.modelname)
              $feedback.data("modelname", data.modelname);
            if (data && data.modelversion)
              $feedback.data("modelversion", data.modelversion);
            $(doc).off(".end-tools-events", ".feedback-button");
            $(doc).one(
              "click.end-tools-events",
              ".feedback-button",
              function (e) {
                var vote =
                  $(e.target).data("value") !== undefined
                    ? $(e.target).data("value")
                    : $(e.target).parents("button").data("value");

                // Add the vote to the hiddden interface.
                $("input.bbm-endtools-feedback-vote").attr("value", vote);
                $feedback.html(
                  '<label class="centered">' +
                    _("Bedankt voor uw feedback") +
                    "</label>"
                );
                bb.updatemis();
              }
            );
            $container.append($feedback);
          }

          // Reveal top or bottom depending on conf.
          if (SHOWONTOP === "false") {
            $("fieldset.selected").append($container);
            setTimeout(function () {
              $container.toggleClass("showing");
            }, 800);
          } else {
            $("#bb-q").prepend($container);
            $("html,body").animate({ scrollTop: 0 });
            setTimeout(function () {
              $container.toggleClass("showing");
            }, 800);
          }
        }
      }
    });
  });
})(jQuery, window, document);
