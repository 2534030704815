import { escapeHTML } from "./escape.js";
import {
  allPass,
  anyPass,
  both,
  complement,
  compose,
  either,
  F,
  has,
  ifElse,
  pipe,
  prop,
  propEq,
  type
} from "./functional.js";
export const isOptional = allPass([
  complement(prop("notnull")),
  either(
    complement(prop("stringmask")),
    pipe(prop("stringmask"), RegExp, re => re.test(""))
  )
]);

export const isVisible = prop("visible");

export const isLabel = either(
  propEq("name", "label"),
  propEq("controltype", "label")
);

export const isOption = either(
  propEq("controltype", "radio"),
  propEq("controltype", "checkbox")
);

export const isLink = propEq("name", "linklabel");

export const isTextual = either(isLabel, isLink);

export const isPicture = propEq("controltype", "picture");

export const isButton = propEq("controltype", "button");

export const isQuestion = complement(anyPass([isTextual, isPicture, isButton]));

export const isReadOnly = both(
  isQuestion,
  either(
    ifElse(has("originalreadonly"), prop("originalreadonly"), prop("readonly")),
    propEq("visible", false)
  )
);

export const isValidatable = ifElse(
  either(isReadOnly, propEq("visible", false)),
  F,
  anyPass([
    has("minimum"),
    has("maximum"),
    both(has("maxlength"), complement(propEq("maxlength", 0))),
    prop("notnull"),
    compose(s => s === "string", type, prop("stringmask"))
  ])
);

export const renderAttribs = attr =>
  Object.entries(attr).reduce(
    (acc, [key, value]) => acc + ` ${key}="${escapeHTML(value)}"`,
    ""
  );

export const setAttribs = (elt, attr) =>
  Object.entries(attr).forEach(([key, value]) => elt.setAttribute(key, value));

export const bbmClass = stylename =>
  `bbm-${stylename.toLowerCase().replace(/[^a-z0-9]/g, "-")}`;
