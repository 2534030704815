import { bb } from "$json";
import { has } from "$json/lib/functional";
import { getmetadata } from "$json/lib/getmetadata";

((doc, $) => {
  let id = "";
  let current = "";
  window.mopinionNodeName = "";
  var js = document.createElement("script");
  js.setAttribute("type", "text/javascript");
  js.setAttribute("src", "//deploy.mopinion.com/js/pastease.js");
  js.async = true;
  document.getElementsByTagName("head")[0].appendChild(js);

  function kill() {
    current = "";

    let mopinion = doc.getElementById("surveyContent");
    mopinion && mopinion.parentNode.removeChild(mopinion);

    let mopinionMask = doc.getElementById("surveyMask");
    mopinionMask && mopinionMask.parentNode.removeChild(mopinionMask);

    let mopinionModal = doc.querySelector(".mopinion-survey-content");
    mopinionModal && mopinionModal.parentNode.removeChild(mopinionModal);

    let headscript = doc.getElementById("mopinionFeedbackScript");
    headscript && doc.head.removeChild(headscript);
  }

  $(doc).on("bb:preHandleData", (e, data) => {
    if (data && has("modelname", data)) {
      if (current !== data.modelname) {
        if (current !== "") kill();
        current = data.modelname;

        var arbitrary = bb.propFinder(bb.conf, "arbitrary");
        var config = arbitrary("mopinion", {});
        if (config && has(data.modelname, config)) {
          id = config[data.modelname].id;
          let t = setInterval(() => {
            try {
              Pastease.load(id);
              clearInterval(t);
            } catch (e) {}
          }, 50);
        }
      }
    } else if (data && has("models", data)) {
      kill();
    }
  });

  $(doc).on("bb:finalHandleData", (e, data) => {
    if (data && data.groups && data.groups.length) {
      let selected = doc.querySelector("fieldset.group.selected");
      window.mopinionNodeName = selected.dataset.node;
      const md = getmetadata(data).props;
      if (md.has("p-mopinion-trigger-show")) {
        window.showMopinion = true;
        kill();
        if (has("config")(Pastease)) {
          Pastease.config(id);
          window.setTimeout(e => (window.showMopinion = false), 1e4);
        }
      }
    }
  });
})(document, jQuery);
