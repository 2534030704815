/*
 * This utility can be imported to ease the use
 * of metadata by pulling it from the data provided.
 * The function will return an object containing three
 * properties:
 *
 * props --> a Map() containing a single entry per metadata property with it's
 *              respective value of that metadata. Accumulated from all controls and
 *              groups. Later groups overriding earlier.
 *
 * groups   --> a Map() containing a single property per group
 *              which has metadata, and the value of that metadata.
 *
 * controls --> a Map() containing a single property per control
 *              which has metadata, and the value of that metadata.
 */

const getmetadata = data => {
  let fromnode = {
    props: new Map(),
    groups: new Map(),
    controls: new Map()
  };

  try {
    data.groups.forEach(group => {
      if (group.metadata !== undefined) {
        fromnode.groups.set(group.groupid, group.metadata);
        Object.keys(group.metadata).forEach(metaprop => {
          fromnode.props.set(metaprop, group.metadata[metaprop]);
        });
      }
      group.controls !== undefined &&
        group.controls.forEach(control => {
          Object.keys(control).forEach(prop => {
            if (prop === "metadata") {
              fromnode.controls.set([control.id], control.metadata);
              Object.keys(control.metadata).forEach(metaprop => {
                fromnode.props.set(metaprop, control.metadata[metaprop]);
              });
            }
          });
        });
    });
    return fromnode;
  } catch (err) {
    window.console && window.console.warn(err);
    return fromnode;
  }
};

export { getmetadata };
