import { bb } from "$json";
/* model-specific-savecase
 *
 * To accompany savecase and only show
 * buttons when specific model/s are in use.
 *
 * Author: Tim Bauwens
 * Copyright 2017 Berkeley Bridge
 *
 */

(function ($, win, doc) {
  $(function () {
    var mdls = ["vastgoed_transformatie"];

    $(doc).on("bb:postHandleData", function (event, data) {
      if (data && data.groups) {
        bb.Mode.toggle("hasSaveCase", mdls.indexOf(data.modelname) !== -1);
      }
    });
  });
})(jQuery, window, document);
