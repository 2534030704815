/* global jQuery */
let $ = jQuery;

import { escapeHTML } from "./escape.js";
import { notify } from "./notify.js";
import { _ } from "./gettext.js";
import { fromApiServer } from "./location.js";

/**
 * Keep track of ajax requests + some convenience
 *
 * Exports busy and replace to bb.ajax object for plugins to use.
 */
var Ajax = {
  busy: false,
  last: null,
  direction: null,

  defaultOptions: {
    dataType: "json",
    type: "POST",
    url: "action",
    cache: false,
    async: true,
    success: checkJSON,
    error: onJSONError
  },

  post: function (options) {
    const _options = Object.assign({}, Ajax.defaultOptions, options, {
      url: fromApiServer(options.url || Ajax.defaultOptions.url)
    });
    $(document).trigger("bb:prePost", _options);
    return $.ajax(_options);
  },

  replace: function (obj) {
    Ajax.last && Ajax.last.abort();
    Ajax.last = Ajax.post(obj);
    return Ajax.last;
  },

  release: function () {
    Ajax.busy = false;
    $(".group.selected").prop("disabled", false);
  }
};

/**
 * Convenience method, like getJSON, but with POST
 *
 * @param {String} url URL where we POST to
 * @param {Object|String} data POST data
 *
 * Use Ajax.post instead if you want to overrule any default
 * settings from Ajax.defaultOptions.
 */
$.postJSON = function (url, data) {
  return Ajax.post({ url: url, data: data });
};

/**
 * The default JSON error handler.
 *
 * Set appropriate flags, let user now what went wrong (as good as
 * possible).
 *
 * Trigger custom 'bb:jsonError' event on document.
 *
 * @param {Object} data XMLHTTPRequest object
 * @param {String} err  Error message
 */
function onJSONError(data, err) {
  Ajax.release();
  $(document).trigger("bb:jsonError", data, err);
  if (data.responseText === undefined) {
    if (data.statusText === "abort");
    else {
      notify(
        { html: false, keepalive: true },
        _("Error: No response from server, server probably down")
      );
    }
  } else if (data.responseText.trim() === "") {
    notify(
      { html: false, keepalive: true },
      _("Error: No response from server, server probably down")
    );
  } else {
    notify(
      { html: true, keepalive: true },
      "Error: " +
        escapeHTML(err) +
        "<br/>Response was:<br/>" +
        "<pre>" +
        escapeHTML(data.responseText) +
        "</pre>"
    );
  }
}

/**
 * Indicate further processing ought to be skipped when this symbol is
 * set on JSON data.
 * @example
 import { stopDispatches } from "$json/lib/ajax";
 $(document).on("bb:preHandleData", (event, data) => {
     event.stopImmediatePropagation(); // prevent further bb:preHandleData handlers.
     data[stopDispatches] = true;
 })
 * @constant
 * @type {Symbol}
 */
export const stopDispatches = Symbol("Skip all further dispatches");

/**
 Set this symbol to a promise on ajax data, and it shall be awaited
 before the next *handleData is run.
*/
const awaiting = Symbol("awaiting promise");

/**
 * The default AJAX success handler. Perform actions on data.

 *
 * If <tt>data</tt> is not a JSON object, do nothing.
 *
 * @todo Either check <tt>data</tt> against JSON API, in some way or the
 * other, or make sure this function doesn't get called in the
 * first place when we're dealing with a non-core request; as
 * checkJSON is currently bound to ALL $.ajax requests,
 * this function may also fire for non-core requests.
 *
 * @param {Object} data 'JSON object according to BB JSON API'
 * @param {String} status Status of XHR
 * @param {Object} req The XHR object retrieving the resource
 *
 * @return undefined
 */
async function checkJSON(data, status, req) {
  if (typeof req.responseJSON !== "undefined") {
    // Since subsequent events may mess with the responseJSON
    // object, give (debuggers) the option to see the raw stuff.
    // Could be solved with a service worker instead.
    $(document).trigger("bb:responseText", req.responseText);
    // use bb:preHandleData to change the JSON object for later invocations
    $(document).trigger("bb:preHandleData", data);
    if (data[stopDispatches]) return;
    await data[awaiting];
    // handleData has the main stuff - the core rendering
    $(document).trigger("bb:handleData", data);
    if (data[stopDispatches]) return;
    await data[awaiting];

    // use bb:postHandleData to change the DOM after initial rendering
    $(document).trigger("bb:postHandleData", data);
    if (data[stopDispatches]) return;
    await data[awaiting];

    // finalHandleData may not change the DOM, but set focus for instance.
    $(document).trigger("bb:finalHandleData", data);
    if (data[stopDispatches]) return;
  }
}

export { Ajax, checkJSON, awaiting };
